import React from "react"
import { Helmet } from "react-helmet"
import { Layout } from "../components/Layout"
import {
  IntegrationsMiddleSectionImageLeft,
  IntegrationsMiddleSectionImageRight,
} from "../integrationsMiddleSection"
import { GetStartedButton, AttributeSection, CTAButton, LearnMoreButton,
Faq, CompareSection } from "../commonComponents"
import {
  IntegrationDarkSection,
  IntegrationHeroSection,
} from "../integrationsUtils"
import { capitalizeFirstLetter } from "../utils"

export default function Salesforce() {
  const SERVICE_NAME = "salesforce"
  const BACKGROUND_SRC = `/img/${SERVICE_NAME}-bg.png`
  const BACKGROUND_WEB_POSITION = "100%"
  const BACKGROUND_MOBILE_POSITION = "50% 50px"
  return (
    <Layout
      keywords="Salesforce integration, Salesforce and Jira integration, Salesforce AI, Salesforce chatbot, Salesforce Agent Assist"
      title="Elevate Salesforce Agent Performance with Salesforce AI Agent Assist and GPT Chatbot"
      description="Boost your salesforce agent's performance with IrisAgent. Enhance support, communication & efficiency. Explore AI-driven solutions on salesforce marketplace. Book a demo!"
    >
    <Helmet htmlAttributes={{ lang: 'en' }}>
        <link
          rel="canonical"
          href="https://irisagent.com/salesforce/"
        />
      </Helmet>

      {/* <!-- ======= Hero Section ======= --> */}
      <IntegrationHeroSection
        serviceName={SERVICE_NAME}
        titleIntegration={
          <>
            Empower your <br />
            <span className="title-fucsia-color">
              {capitalizeFirstLetter(SERVICE_NAME)} agents <br/>
            </span> with Salesforce AI
          </>
        }
        descriptionIntegration={
          <>
            <span>
              Resolve support tickets faster,
              <br/>improve agent productivity, and deflect
              <br/>more than 40% of tickets with IrisGPT
              <br/>chatbot and Salesforce AI integration
            </span>
          </>
        }
        sectionBackgroundSrc={BACKGROUND_SRC}
        sectionBackgroundWebPosition={BACKGROUND_WEB_POSITION}
        sectionBackgroundMobilePosition={BACKGROUND_MOBILE_POSITION}
      />
      {/* <!-- End Hero --> */}
      <br />
      {/*  ======= Cta Section =======  */}
      {/*<section id="cta" className="cta">
        <div className="container">
          <div className="text-center">
            <h3>Scale your customer support team</h3>
            <OutboundLink
              className="cta-btn1"
              href="https://web.irisagent.com"
              target="_blank"
            >
              GET STARTED
            </OutboundLink>
          </div>
        </div>
      </section>*/}
      {/*  End Cta Section  */}
      {/*  ======= Cta Section =======  */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Scale your{" "}
            <span className="title-fucsia-color">customer support team</span>
          </span>
        }
        button={
          <CTAButton
            to="https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FZ4X6UAL"
            label={`FIND US ON ${SERVICE_NAME.toUpperCase()} MARKETPLACE`}
            target="_blank"
          />
        }
        firstColClassName="col-md-8"
        secondColClassName="col-md-4"
        buttonWebFlexAlignment="end"
      />
      {/*  End Cta Section  */}
      {/* <!-- ======= Middle Section ======= --> */}

      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/salesforceMiddle1.webp"
        imageTextMiniature="/img/salesforceMiniature1.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>
            Resolve common cases
            <br /> automatically with AI
          </>
        }
        descriptionTextContent={
          <>
            Access appropriate resolutions based on similar cases, knowledge
            base articles, and AI models from within Salesforce. Automated machine learning within the Salesforce platform ensures that enterprises can fully utilize machine learning capabilities that are production-ready.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/irisgpt.svg"
        imageTextMiniature="/img/salesforceMiniature2.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Deflect 40% of cases and chats with Salesforce AI and IrisGPT Chatbot integration</>
        }
        descriptionTextContent={
          <>
            Trained on the world's largest large language models, IrisGPT's generative AI generates multilingual, trustworthy, and highly accurate answers integrated in your Salesforce chatbot
          </>
        }
      />
      <IntegrationsMiddleSectionImageLeft
        mainImage="/img/salesforceMiddle3.webp"
        imageTextMiniature="/img/salesforceMiniature3.webp"
        backgroundSectionColor="#FFFFFF"
        descriptionTextTitle={
          <>
            Automate ticket tagging, routing and
            <br /> prioritizing within Salesforce
          </>
        }
        descriptionTextContent={
          <>
            IrisAgent discovers tags customized to your domain and automatically
            tags new tickets. Utilizing CRM data, it enhances ticket tagging and routing by providing personalized, data-driven automation. Get rid of manual and inaccurate ticket tagging.
          </>
        }
      />
      <IntegrationsMiddleSectionImageRight
        mainImage="/img/salesforceMiddle2.webp"
        imageTextMiniature="/img/salesforceMiniature2.webp"
        backgroundSectionColor="#F9F9F9"
        descriptionTextTitle={
          <>Add product context from DevOps tools to Salesforce cases</>
        }
        descriptionTextContent={
          <>
            Instant problem discovery using internal operational and product signals without the need for separate data preparation. Utilizing customer data helps in understanding the business priority and history of the customer and empathize better. Link tickets to known product bugs in Jira.
          </>
        }
      />

      {/* <!-- End Middle Section --> */}

      {/* <!-- ======= Counts Section ======= --> */}
      <CompareSection theme="dark" service="Salesforce"/>
      {/* <!-- End Counts Section --> */}

      {/* //   <!-- ======= Attribute Section ======= --> */}
      <AttributeSection />
      {/* //   <!-- ======= END -- Attribute Section ======= --> */}
      <section
        className="d-flex align-items-center sub-section-3-container"
        // style={{ margin: "100px" }}
      >
        <div className="">
          <div className="row">
            <div className="col-sm order-1 order-lg-1">
              <div style={{ marginLeft: "40px" }}>
                <p className="sub-section-3-h1">Any questions?</p>
                <p className="sub-section-3-h2"> We got you.</p>
                <img
                  src="/img/security-5.svg"
                  className="img-fluid"
                  alt="secure"
                />
              </div>
            </div>
            <div className="col-sm pt-5 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center">
              <div id="accordion">
                <Faq
                  id="faq1"
                  question="How does IrisAgent enhance agent productivity and customer service for Salesforce customers?"
                  answer="IrisAgent enhances customer service by providing fast, accurate, and personalized responses, thereby nurturing stronger relationships across various communication channels. Salesforce products, including those in the Customer 360 portfolio, play a crucial role in enhancing customer service by integrating seamlessly with IrisAgent.

Its advanced capabilities, including AI Agent Assist enable it to comprehend human language nuances, and assist in ensuring a seamless and cohesive brand experience for Salesforce customers and their customers."
                />
                <Faq
                  id="faq2"
                  question="Can IrisAgent integrate with Salesforce's existing AI capabilities?"
                  answer="Yes, IrisAgent is seamlessly integrated with Salesforce solutions platform, complementing its existing AI capabilities. IrisAgent ensures data privacy and security, allowing organizations to deploy AI models without introducing risk. Through this integration, Salesforce customers can leverage the power of IrisAgent to enhance their customer engagement strategies and drive operational efficiency."
                />
                <Faq
                  id="faq3"
                  question="How does IrisAgent AI resolve support cases in Salesforce faster?"
                  answer="IrisAgent Agent Assist AI and IrisGPT in Salesforce saves agents' time and effort by automating responses to common, repetitive support queries. It also provides agents with real-time, context-aware response suggestions, enabling them to resolve complex issues faster and more accurately. This results in improved customer satisfaction and reduced ticket resolution times."
                />
                <Faq
                  id="faq4"
                  question="How does IrisAgent AI automate ticket tagging, routing, and sentiment analysis in Salesforce?"
                  answer="IrisAgent automatically identifies tags specific to your domain or industry using advanced AI solutions and applies them to new tickets without agent intervention. By leveraging customer data, our AI also analyzes Salesforce tickets for language cues that reveal the sentiment. IrisAgent routes tickets to the right agent based on their domain and expertise. It uses built-in analytics to identify those product features with the most negative reactions so that your support team agents and product teams can better understand the source of the most ‘how-to’ tickets."
                />
                <Faq
                  id="faq5"
                  question="How can Salesforce customers get started with IrisAgent?"
                  answer="It takes just a few minutes to get started with IrisAgent. Salesforce customers can install the IrisAgent app from the Salesforce marketplace and start using it within Salesforce right away. This integrated set of AI technologies leverages advanced AI capabilities to enhance your CRM experience. IrisAgent is designed to be user-friendly and intuitive, so you can start leveraging its advanced AI capabilities without any technical expertise."
                />
                <Faq
                  id="faq6"
                  question="What channels does IrisAgent support for communication?"
                  answer="IrisAgent supports multiple communication channels, including email, chat, and social media platforms, with advanced AI tools to enhance these interactions. These AI tools also help in automatically generating product descriptions, which play a crucial role in creating a unified experience across all channels. Its omnichannel approach enables Salesforce customers to engage and connect with their customers wherever they are, providing a unified experience across all channels.

"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======= Cta Section ======= --> */}
      <IntegrationDarkSection
        label={
          <span className="integration-dark-section-title">
            Try out {" "}
            <span className="title-fucsia-color">
              IrisGPT
            </span> on your own data for free
          </span>
        }
        button={<LearnMoreButton

            label="TRY NOW FOR FREE"
            to="/try-for-free"
          />}
      />
      {/* <!-- End Cta Section --> */}
      {/* <!-- ======= Cta Section ======= --> */}
      {/* <ContactSection></ContactSection> */}
      {/* <!-- End Cta Section --> */}
    </Layout>
  )
}
